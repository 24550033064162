.why-us-header {
  text-align: center;
}

.why-us-svg {
  height: 200px;
  width: 130px;
  object-fit: contain;
}

@media only screen and (max-width: 766px) {
  .why-us-svg {
    height: 150px;
    width: 100% !important;
    object-fit: contain;
    overflow: auto;
  }
}
