.portal {
  position: relative;
  width: 40vw;
  height: 100vh;
  z-index: 5;
}

.portal-container {
  position: fixed;
  top: -1px;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* -webkit-transform: translateX(-50%); */
  /* transform: translateX(-50%); */
  z-index: 5;
}
@media only screen and (min-width: 1920px) {
  .portal {
    width: 30vw;
  }
}
@media only screen and (max-width: 1024px) {
  .portal {
    width: 50vw;
  }
}
@media only screen and (max-width: 768px) {
  .portal {
    width: 80vw;
  }
}
@media only screen and (max-width: 600px) {
  .portal {
    width: 100vw;
  }
}
