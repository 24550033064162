.nav-items {
  text-align: "center";
  color: var(--header-color);
  font-size: 15;
  font-weight: 700;
  margin-right: 2rem;
  cursor: pointer;
}

.nav * button {
  height: 3rem !important;
  margin-top: 0 !important;
}

.invert {
  filter: invert(100%);
}
