.expander-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.expander {
  margin: 0.3rem 0;
}
.expander-header > * {
  font-size: 1.4rem;
  margin: 0 0;
}
.MuiStep-horizontal:first-child {
  padding-left: 0 !important;
}
.MuiStepLabel-label {
  font-weight: bold !important;
  color: var(--main-font-color) !important;
}
.Mui-completed.MuiStepIcon-root {
  color: var(--main-font-color) !important;
}
.expander-header .exp-btn {
  width: 3rem !important;
  min-width: unset;
  border-radius: 50%;
  cursor: pointer;
  padding: 0 !important;
  border: 1px solid var(--border-color) !important;
  background-color: transparent;
  background-image: none;
  color: var(--main-font-color);
  font-weight: bold;
  height: 3rem;
  margin-bottom: 0rem;
}

.expander-header .btn-small.btn-circle {
  height: 5rem !important;
  width: 5rem !important;
  margin-bottom: 1rem;
  min-width: auto !important;
}
