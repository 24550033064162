.humans-card {
  border-radius: 20px;
  /* background: var(--card-background); */
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
}
.humans-card.with-border {
  border: 1px solid var(--main-font-color);
  box-shadow: none;
}

.horizontal-card {
  width: 100%;
  height: 90%;
  color: var(--main-font-color);
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding: 2rem 3rem 4rem;
}
.horizontal-card .header {
  font-size: 1.6rem;
}

@media only screen and (max-width: 766px) {
  .humans-card {
    margin-top: 5rem;
    width: 100%;
    height: auto;
  }
  .humans-card.horizontal-card {
    margin-top: 0;
  }
  .humans-img-card {
    margin-bottom: 2rem;
  }
}
