.btn-primary {
  padding: 0rem 1.6rem;
  background: transparent;
  border: none;
  height: var(--button-height);
  display: inline-flex;
  justify-content: center;
  min-width: 10rem;
  align-items: center;
  position: relative;
  background: var(--btn-gradient);
  /* letter-spacing: 0.2rem; */
  color: var(--btn-gradient-color);
  /* text-transform: uppercase; */
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  margin-right: 1rem;
  z-index: 2;
  margin-bottom: 2rem;
  white-space: nowrap;
  font-size: 1.2rem;
  transition: all 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}
.btn-primary * {
  /* font-weight: bolder !important; */
}
.btn-primary .fas {
  font-size: 1.5rem;
}
.jumbotron-action .btn-primary .fas {
  margin-right: 1rem;
  font-size: 1.8rem;
}
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-chevron {
  position: absolute;
  right: 01rem;
  top: 1rem;
  background: transparent;
  color: var(--main-font-color);
  border: none;
  -webkit-tap-highlight-color: transparent;
  padding: 1rem;
  z-index: 1;
}
.btn-circle {
  width: var(--button-height) !important;
  min-width: unset;
  height: var(--button-height) !important;
  border-radius: 50%;
  padding: 0 !important;
  border: 2.622px solid var(--main-font-color);
}
h1 > button {
  position: relative;
  top: -5px;
}
.btn-primary:active {
  transform: scale(0.99);
}
.btn-primary.green {
  color: var(--green);
  background: var(--button-color);
}
.btn-primary.red {
  color: var(--red);
  background: var(--button-color);
}
.btn-primary.orange {
  color: var(--orange);
  background: var(--button-color);
}
.btn-primary.purple {
  color: var(--purple);
  background: var(--button-color);
}
.btn-primary.blue {
  color: var(--blue);
  background: var(--button-color);
}

.btn-primary.anti-red {
  color: var(--white);
  background: var(--red);
}
.btn-primary.anti-orange {
  color: var(--white);
  background: var(--orange);
}
.btn-primary.anti-purple {
  color: var(--white);
  background: var(--purple);
}
.btn-primary.anti-blue {
  color: var(--white);
  background: var(--blue);
}
.btn-primary.anti-green {
  color: var(--white);
  background: var(--green);
}
.answer-actions > button:not(.collapse-read-btn) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--main-background-color);
  color: var(--glass-button-font-color);
  cursor: pointer;
  font-size: 1.4rem;
  height: 4rem;
}
.answers-new-container .answer-actions > button:not(.collapse-read-btn) {
  width: 22%;
  color: var(--theme-color);
}

.btn-small {
  padding: 0 1rem;
  font-size: 1rem;
  height: 3rem !important;
  min-width: 4rem;
}
.btn-primary > span:first-child {
  margin-left: 0;
  margin-right: -0.5rem;
  display: flex;
  align-items: center;
  color: inherit !important;
  justify-content: center;
  width: 100%;
}
.btn-primary > span:last-child {
  margin-right: 0rem;
}
/* .btn-primary:focus {
  color: var(--main-font-color);
} */
.btn-accent {
  /* color: var(--main-font-color); */
  background-color: transparent;
  background-image: none;
  color: var(--main-font-color);
  /* font-weight: bold; */
  height: var(--button-height);
  margin-bottom: 2rem;
  border: 2px solid var(--main-font-color);
}
.btn-accent.btn-accent-alt {
  color: var(--main-background-color);
}

.btn-colored {
  background: var(--theme-color);
  color: var(--btn-gradient-color);
  border-color: var(--theme-color);
  /* box-shadow: var(--btn-primary-shadow); */
}

.btn-accent:focus,
.btn-accent:active {
  box-shadow: none !important;
}

button:focus {
  outline: none;
}
.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
  background: var(--btn-disabled) !important;
  color: var(--main-font-color);
}
.btn-no-hover:hover {
  box-shadow: none;
}
a {
  color: var(--main-font-color);
}

.btn-white-font {
  color: var(--white);
}
.btn-white {
  background: var(--main-background-color);
  color: var(--main-font-color);
}
.btn-white:focus {
  color: #111;
}

.btn-big {
  padding: 3.5rem 3rem !important;
  height: var(--button-height);
  font-size: 1.4rem;
  min-width: 100px !important;
}
.btn-round {
  border-radius: 55px;
}
.btn-full-round {
  border-radius: 40px !important;
}
.navbar.navbar-top .btn-round {
  border-radius: var(--radius);
}
.btn-mar-right {
  margin-right: 1rem;
}
.no-mar-right {
  margin-right: 0;
}
.btn-primary .fa-check-circle {
  font-size: 2rem;
  margin-right: 1rem;
}
.btn-with-border {
  background: transparent;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 52.439px;
  border: 2.622px solid var(--main-font-color);
  margin-left: 10px;
  color: var(--btn-gradient);
}
.fileContainer p {
  width: 100%;
  text-align: center;
}
.fileContainer .uploadIcon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top: 2rem;
}
.fileUploader > .fileContainer {
  background-color: var(--main-background-color) !important;
  align-items: flex-start;
  box-shadow: none;
  border-radius: var(--radius);
  padding: 4rem 6rem;
  box-sizing: border-box;
}
.fileUploader > .fileContainer .chooseFileButton {
  background: var(--border-color) !important;
  color: var(--main-font-color) !important;
  font-family: var(--font-one) !important;
  border-radius: var(--radius);
  font-weight: normal;
  height: 6rem;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
button {
  font-family: var(--font-one), sans-serif;
}
button:focus {
  outline: none;
  box-shadow: none;
}
.btn-very-small {
  height: 2.8rem;
  margin-left: 1rem !important;
  font-size: 1rem;
  padding: 0 1.2rem;
  box-shadow: none;
  border: 1px solid;
  margin-top: 0 !important;
}
.btn-no-shadow,
.btn-no-shadow:hover,
.btn-no-shadow:focus {
  box-shadow: none !important;
}
.btn-danger,
.btn-danger:hover {
  color: var(--white);
  border-color: var(--error-font-color);
  box-shadow: none;
  background-color: var(--error-font-color);
}
.btn-alt,
.btn-alt:hover {
  color: var(--theme-color);
  background: var(--main-background-color);
}
.btn-primary:last-child {
  margin-right: 0;
}
.btn-primary {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
button div {
  background-color: var(--theme-color) !important;
  border-radius: 50%;
  color: var(--white) !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  margin-left: 2rem;
  font-size: 2rem;
  margin-right: 5px;
}

.btn-link {
  padding: 0 !important;
  min-width: auto !important;
  height: 3rem !important;
  color: var(--main-font-color) !important;
  background: none !important;
  display: inline-flex !important;
  width: auto !important;
  box-shadow: none !important;
  margin-right: 4rem !important;
}
.simple-link {
  text-decoration: underline !important;
  font-weight: bold;
}
.btn-link:hover {
  text-decoration: underline !important;
}
button .lnr {
  font-size: 3rem !important;
}
.button-active-tab {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--theme-color);
  border-radius: var(--radius);
}
.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--main-font-color);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--main-background-color);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--main-background-color);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.has-check {
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--main-background-color);
  }
}

.no-full-on-mobile {
  width: auto !important;
  min-width: 9rem !important;
}

@media only screen and (max-width: 500px) {
  .btn-with-border {
    margin-left: 0;
  }
  .btn-primary .fas {
    /* margin-right: 0.5rem; */
  }
  .btn-primary {
    /* width: 100%; */
    /* min-width: auto; */
    margin-top: 1rem !important;
  }

  .btn-small {
    width: auto;
    height: 2rem;
  }
  .btn-circle {
    font-size: 2rem;
    margin-top: 0 !important;
  }
  .btn-very-small {
    height: 2.4rem;
  }
  *button[class="btn-primary"]:hover {
    box-shadow: initial !important;
  }
}
